export const useShowPatent = (queryType: 'params' | 'query' = 'params') => {
  const route = useRoute()
  const patentId = computed(() => route[queryType].patentId as string)
  const { axios, refetchQuery, onError } = useApi()
  const { firestore } = useFireStore()
  const query = useQuery({
    queryFn: async () => {
      if (!firestore.value || !patentId.value) {
        return null
      }

      const { data } = await axios.get<ApiShow<PatbasePatent & Patent>>(
        `patents/${patentId.value}`,
        {
          params: {
            relations: [
              'family',
              'legalEvents',
              'documents',
              'children',
              'parent'
            ]
          }
        }
      )

      return data.data
    },
    onError,
    queryKey: ['patents', patentId, firestore, queryType]
  })
  return { axios, query, route, refetchQuery, patentId }
}

export const usePatents = (params: globalThis.Ref) => {
  const { axios, getNextPageParam, onError } = useApi()
  return useInfiniteQuery({
    queryFn: async ({ pageParam }) => {
      if (!params.value) {
        return null
      }
      const response = await axios.get<ApiList<Patent>>('patents', {
        params: {
          sortBy: 'publication_date',
          sortOrder: 'desc',
          page: pageParam,
          ...params.value
        }
      })
      return response.data
    },
    onError,
    queryKey: ['patents', params],
    getNextPageParam
  })
}

export const patentStatusTitles: Record<PatentStatus | 'all', string> = {
  all: 'All Assets',
  application: 'Applications',
  grant: 'Grants',
  provisional: 'Provisional'
}
